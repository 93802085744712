import './src/styles/index.scss'
import './src/components/Sidebar/Backdrop/Backdrop.css';
import "./src/components/Sidebar/SideDrawer/SideDrawer.css";
import "react-dropdown/style.css";
import { isMobile } from 'react-device-detect'

// const deviceDetect = require('react-device-detect');


export const onRouteUpdate = () => {
    if (typeof window !== `undefined`) { 
        var elmnt = document.getElementById("tip-post");
        if(elmnt && isMobile){
            elmnt.scrollIntoView();
        }else{
            window.scrollTo(0,0);
        }
    }
  }
  
  export const shouldUpdateScroll = args => {
     return false;
  };