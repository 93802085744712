exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-bm-category-js": () => import("./../../../src/templates/BM-category.js" /* webpackChunkName: "component---src-templates-bm-category-js" */),
  "component---src-templates-bm-post-js": () => import("./../../../src/templates/BM-post.js" /* webpackChunkName: "component---src-templates-bm-post-js" */),
  "component---src-templates-bm-translated-index-js": () => import("./../../../src/templates/BM-translatedIndex.js" /* webpackChunkName: "component---src-templates-bm-translated-index-js" */),
  "component---src-templates-tutorial-device-tips-js": () => import("./../../../src/templates/tutorial-deviceTips.js" /* webpackChunkName: "component---src-templates-tutorial-device-tips-js" */)
}

